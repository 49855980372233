import * as React from "react"

const TwitterShare = ({
    url = "",
    via = "",
    tags = [],
    dataShowCount = true
}) => {
    React.useEffect(() => {
        const scriptTag = document.createElement("script");
        scriptTag.src = "https://platform.twitter.com/widgets.js";
        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        };
    }, []);

    return (
        <a
            href="https://twitter.com/share"
            class="twitter-share-button"
            data-via={via}
            data-url={url}
            data-show-count={dataShowCount}
        >
            ツイート
        </a>
    );
};

export default TwitterShare;